body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.leaflet-control-attribution {
  display: none;
}

.results-header {
  justify-content: space-between;
  width: 100%;
  display: flex;
}

.results-header div {
  margin-right: 20px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 2px;
  padding-bottom: 3px;
  border-radius: 5px;
}

.results-header .incomplete, .results-header .released {
  background-color: #dddddd;
}

.results-header .complete {
  background-color: #5bd778;
}

.results-header .result-status-container .status-msg {
  color: black;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 2px;
  padding-bottom: 3px;
  border-radius: 5px;
}

.results-table .bi-check-lg, .results-table .bi-plus {
  color: #28A745;
  font-size: 20px;
}

.result-status-container {
  display: flex;
}

.results-table .bi-plus {
  line-height: 20px;
  font-size: 25px;
  position: relative;
  top: 2px;
}

.accordion {
  max-width: 1000px;
}

.toast-body {
  color: white;
}

.back-btn {
  margin-bottom: 20px;
}

.quiz-container {
  display: flex;
  
}

.edit-score-container {
  margin-bottom: 10px;
}

.edit-score-container input, .edit-score-container button {
  margin-right: 10px;
}