body {
  color: #2d2d2d;
}

.App {
  margin: 20px;
}

h1 {
  font-size: 30px;
}

h2 {
  font-size: 26px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.card {
  max-width: 250px;
}

.student-map .leaflet-container {
  position: absolute;
}

.leaflet-container {
  width: calc(100% - 30px);
  margin-top: 15px;
  margin-bottom: 15px;
  bottom: 0;
}

.instructor-map .leaflet-container {
  height: 500px;
  width: 100%;
  max-width: 1000px;
}

.map .leaflet-container {
  height: calc(100% - 180px);
  width: calc(100% - 30px);
}

.visually-disabled {
  opacity: .5;
  cursor: pointer;
}

.visually-disabled:hover {
  background-color: #198754;
}

.answer-popup {
  background-color: white;
  position: absolute;
  z-index: 999;
  border-radius: 3px;
  box-shadow: 10px 10px 8px rgba(0, 0, 0, 0.3);
}

.answer-popup .handle {
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 7px;
  padding-right: 7px;
  background-color: #4597D0;
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
  cursor: move;
  color:white;
  display: flex;
  justify-content: space-between;
}

.answer-popup .handle .close {
  cursor: pointer;
}

.answer-popup form {
  padding: 20px;
  border-left: 1px solid #e8e8e8;
  border-right: 1px solid #e8e8e8;
  border-bottom: 1px solid #e8e8e8;
}

.fade-transition {
  transition: .2s;
  opacity: 1;
}

.hidden {
  opacity: 0;
}

.submit-container {
  display: flex;
  align-items: center;
}

.submit-container button {
  margin-right: 20px;
}

.submit-container div {
  font-size: 18px;
}

.submit-container .complete {
  font-weight: bold;
}

.test-list {
  max-width: 1200px;
}

.test-list .card {
  float: left;
  margin-right: 20px;
  margin-bottom: 20px;
}
